/* NOTE: Using single or double quotes instead of backticks errors out! */

@font-face {
  font-family: `Graphik`;
  src: url(`./Graphik-Light-Web.woff`) format(`woff`);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: `Graphik`;
  src: url(`./Graphik-Medium-Web.woff`) format(`woff`);
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: `Graphik`;
  src: url(`./Graphik-Regular-Web.woff`) format(`woff`);
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: `Plantin-MT-Pro-Light`;
  src: url(`./Plantin-MT-Pro-Light.woff`) format(`woff`);
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
