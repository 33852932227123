.lazyload-wrapper {
    height: 100%;
}

.hero-input-field {
    text-align: center;
    background: #FFFFFF;
    padding: 8.7px 13px;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    margin-bottom: 4px;
    min-height: 44px;
    outline: none;
    border: 1px solid #201A19;
}

@media (min-width: 768px) {
    .hero-input-field {
        text-align: left;
        margin-bottom: 0px;
    }

    @media (min-width: 833px) {
        .hero-input-field {
            border: none;
        }
    }
}


::-webkit-scrollbar {
    display: none;
}
